// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// material icon @Google
@import '~material-design-icons/iconfont/material-icons.css';


// mystyles 
@import 'mystyle'; 

body{
    display:flex;
    justify-content: center;
    align-items: center;
    border:1px solid yellow;
    min-height:100vh;

    div#app{
        width:100%;
        max-width:500px;
        display:flex;
        justify-content: center;
        align-items: center;

        div#uploader{
            width:100%;
            // height:400px;
            background-color: white;
            box-shadow: 0px 0px 19px 0px #5b5b5b;
            border-radius: 10px;
            
            text-align: center;
            // display:flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;

            #dropimage{
                width:80%;
                height:150px;
                margin:0 auto; 
                background-color: #F6F8FB;
                border:2px dashed #97BEF4; 
                border-radius:5px;
                display:flex;
                justify-content: space-around;
                flex-direction: column;
                span{
                    &.material-icons{
                        &.big{
                            font-size:6em;
                        }
                    }
                }
            }

            #load{
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;

                #loadingbar{
                    width:95%;
                    height:7px;
                    background-color:#cfcfcf;
                    border-radius:5px;
                    position:relative;
                    overflow:hidden;
    
                    &::before{
                        content:"";
                        width:15%;
                        height:7px;
                        background-color:blue;
                        position:absolute;
                        top:0; left:-15%;
                        animation: loadbar 2s infinite normal;
                    }
                }
            }
        }



        footer{
            text-align: center;
            margin-top:10%;
        }
    }




}

@keyframes loadbar{
    from{ left: -15%; }
    to{ left: 100%; }
}